dışa aktarma türü StreamResponseChunk<t> = {
    iteratorResult: IteratorResult<t>;
    sonraki?: Söz ver<streamresponsechunk<t>>;
};

/**
 * Bir asenkron üreteç fonksiyonunun yanıtını aktarın.
 * Bu işlev, bir asenkron oluşturucu sunucu eylemini sarmak için kullanılmalıdır.
 *
 * Bkz. https://github.com/vercel/next.js/discussions/51282
 */
export function streamResponse<t, P="" extends="" any[]="">(
    createGenerator: (...args: P) => AsyncGenerator<t>,
) {
    return async (...args: Parameters<typeof createGenerator="">) => {
        const generator = createGenerator(...args);
        return streamChunk<t>(jeneratör);
    };
}

/**
 * Bir asenkron jeneratör fonksiyonunun yanıtı üzerinde yineleme yapın.
 */
export function iterateStreamResponse<t>(streamResponse: Promise<streamresponsechunk<t>>) {
    return {
        [Symbol.asyncIterator]: function () {
            return {
                current: streamResponse,
                async next() {
                    const { iteratorResult, next } = await this.current;

                    if (next) this.current = next;
                    else iteratorResult.done = true;

                    return iteratorResult;
                },
            };
        },
    };
}

async function streamChunk<t>(jeneratör: AsyncGenerator<t>) {
    const next = generator.next();
    return new Promise<streamresponsechunk<t>>((resolve, reject) => {
        next.then((res) => {
            if (res.done) resolve({ iteratorResult: res });
            else resolve({ iteratorResult: res, next: streamChunk(generator) });
        });
        next.catch((error) => reject(error));
    });
}
</streamresponsechunk<t></t></t></streamresponsechunk<t></t></t></typeof></t></t,></streamresponsechunk<t></t></t>